<template>
    <div class="section">
        <div class="container">
            <div class="columns is-centered">
                <div class="column is-6">
                    <h1>Forgot password</h1>
                    <p>Enter the email address associated with your account and we will send you a link to reset your password.</p>
                    <div class="my-4 box">
                        <o-field label="Email">
                            <o-input v-model="email" placeholder="Email address"></o-input>
                        </o-field>
                        <o-button class="is-primary" @click="sendEmail">Send reset link</o-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getCurrentInstance, ref, computed, watch, onMounted, inject } from "vue";
    export default {
        props: {},
        emits: [],
        setup() {
            const global = getCurrentInstance()?.appContext.config.globalProperties;
            const $http = inject("$http");
            const $success = inject("$success");
            const $error = inject("$error");

            const email = ref("");

            const sendEmail = async () => {
                try {
                    var response = await $http.get("/api/user/forgotpassword/" + email.value);
                    $success(response.data);
                } catch (err) {
                    $error(err.response.data);
                }
            }

            return {
                email,
                sendEmail
            }
        }
    }
</script>